<template>
  <!--通讯录模块-->
  <div>
    <el-card shadow="never">
      <div class="where">
        <el-form
            :model="where"
            label-width="90px"
            class="ele-form-search"
            @submit.native.prevent>
          <el-row :gutter="15">
            <!--<el-col :lg="21" :md="12">-->
            <!--  <el-form-item label="服务商名称:">-->
            <!--    <el-select-->
            <!--        style="width: 300px !important;"-->
            <!--        clearable-->
            <!--        v-model="where.gender"-->
            <!--        placeholder="请选择服务商"-->
            <!--        class="ele-fluid">-->
            <!--      <el-option label="服务商名称1" value="1"/>-->
            <!--      <el-option label="服务商名称1" value="2"/>-->
            <!--      <el-option label="服务商名称1" value="3"/>-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <el-col :lg="3" :md="12">
              <div  class="ele-form-actions">
                <div class="custom" style="margin-left: 20px">
                  <el-button class="custom-button" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}custom:addressbook:save`]">
                    <!--el-icon-devops 是自己命名的-->
                    <i class="el-icon-tianjia" />
                    <span style="vertical-align: middle">添加</span>
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--数据表格-->
      <div class="dataList">
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
        >
          <!--自定义列显示 示例-->
          <template v-slot:type= "{ scope }">
            <div v-for="(item) in dict.type.addressbooktype">
              <!--{{item.label}}-->
              <span v-if="scope.row.type == item.value">{{item.label}}</span>
            </div>
          </template>

          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-link :underline="false" type="warning" v-auths="[`${$config.uniquePrefix}custom:addressbook:put`]" @click="openEdit(scope.row)" style="margin-right: 10px;">编辑</el-link>
            <el-popconfirm title="确定执行此操作吗？" @confirm="del(scope.row)">
              <el-link slot="reference" :underline="false" v-auths="[`${$config.uniquePrefix}custom:addressbook:delete`]">删除</el-link>
            </el-popconfirm>
          </template>
        </my-table>
      </div>
    </el-card>

    <!-- 编辑弹窗 -->
    <addressbook-edit :data="current" :id="id" :dict="dict" :visible.sync="showEdit" @done="getList"/>

  </div>
</template>

<script>
// 引入编辑通讯录弹窗
import AddressbookEdit from './components/addressbook-edit.vue';

// 引入的接口
import {
  getaddress_book,
  Deladdress_book
} from '@/api/custom'
// 权限
import {ShowTable} from '@/utils'

export default {
  components:{
    AddressbookEdit
  },
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    // 字典数据
    dict:Object,
  },
  data(){
    return{
      // 搜索条件
      where:{
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:false,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true
        },
        {
          label: "岗位",
          prop: "type",
          slot: 'type',
          isShow: true
        },
        {
          label: "客户",
          prop: "realname",
          isShow: true
        },
        {
          label: "电话",
          prop: "phone",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 时效数据
      current: null,
      // 是否显示时效弹窗
      showEdit: false,

    }
  },

  mounted() {
    console.log('通讯录父级id',this.id);
    console.log(this.dict.type.addressbooktype);

    this.getList();
  },

  methods:{
    // 编辑
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    // 获取列表
    getList(){
      getaddress_book(this.id,this.where).then(async (res) => {
        console.log('通讯录列表',res)
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },


    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      // console.log(row);
      Deladdress_book(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },


  }

}
</script>

<style lang="scss" scoped>
.content{

}
.dataList{
  margin-top: 10px;
}
</style>
