<template>
  <div>
    <div class="title">
      <div class="title_t">
        <span>客户详情</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <!--选项-->
    <div class="xuanxiang">
      <div class="xuanxiang_k" v-for="(item,index) in activeList" :key="index" :class="{xuanxiang_k1:index==isShow}" @click="xuanxiangclick(item,index)">
        <!--<img :src="item.img" alt="">-->
        <span>{{item.name}}</span>
      </div>
    </div>

    <!--基本信息 模块-->
    <div v-if="isShow == 0">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <div class="card" style="padding: 20px !important;">
          <div class="condition">
            <div class="condition_t">
              <div class="condition_t_icon"></div>
              <div class="condition_t_text">
                基本信息
              </div>
            </div>
            <!-- 信息 -->
            <div class="condition_b">
              <!--<div class="condition_b_l">-->
              <!--  &lt;!&ndash;<div class="condition_b_img">&ndash;&gt;-->
              <!--  &lt;!&ndash;  <img class="condition_b_img_anniu" src="@/assets/images/home/custom/upload_anniu.png" alt="">&ndash;&gt;-->
              <!--  &lt;!&ndash;  <div class="condition_b_img_text">上传营业执照</div>&ndash;&gt;-->
              <!--  &lt;!&ndash;</div>&ndash;&gt;-->
              <!--  <div class="upload_bg_yingyezhizhao">-->
              <!--    <el-upload-->
              <!--        :limit="1"-->
              <!--        :file-list="fileList"-->
              <!--        :action="action"-->
              <!--        :headers="headers"-->
              <!--        :on-success="successUpload"-->
              <!--        :class="{hide_box: businessLicenseupload_btn}"-->
              <!--        :on-change="businessLicensechange"-->
              <!--        list-type="picture-card"-->
              <!--        :on-preview="businessLicensePreview"-->
              <!--        :on-remove="businessLicensehandleRemove">-->
              <!--      &lt;!&ndash;<i class="el-icon-plus"></i>&ndash;&gt;-->
              <!--      <img style="margin-top: 60px;" src="@/assets/images/home/upload_anniu.png" alt="">-->
              <!--      <div style="font-size: 16px;font-weight: 500;color: #ff9b05;margin-top: 15px;">上传营业执照</div>-->
              <!--    </el-upload>-->
              <!--    <el-dialog :visible.sync="businessLicensedialogVisible">-->
              <!--      <img width="100%" :src="businessLicensedialogImageUrl" alt="">-->
              <!--    </el-dialog>-->
              <!--  </div>-->
              <!--</div>-->
              <div class="condition_b_r">
                <el-row :gutter="15">
                  <el-col :span="12">
                    <div>
                      <div style="display: flex;align-items: center;">
                        <el-form-item label="所属集团" prop="parent_id">
                          <!--<el-input clearable style="" v-model="form.channel_name" placeholder="请输入客户名称"></el-input>-->
                          <el-select v-model="form.parent_id" clearable placeholder="请选择" @change="selectchange">
                            <el-option
                                v-for="(item,index) in List"
                                :key="index"
                                :label="item.label"
                                :value="parseInt(item.value)">
                            </el-option>
                          </el-select>
                          <!--<el-select v-model="form.ic_id" clearable placeholder="请先选择一级" style="margin-left:20px;" :disabled="disabled">-->
                          <!--  <el-option-->
                          <!--      v-for="(item,index) in subordinateList"-->
                          <!--      :key="index"-->
                          <!--      :label="item.label"-->
                          <!--      :value="parseInt(item.value)">-->
                          <!--  </el-option>-->
                          <!--</el-select>-->
                          <!--<el-cascader-->
                          <!--    v-model="form.ic_id"-->
                          <!--    :options="List"-->
                          <!--    @change="handleChange"></el-cascader>-->
                        </el-form-item>
                        <!--<el-form-item label="" prop="" label-width="0">-->
                        <!--  <el-select v-model="form.ic_id" clearable placeholder="请先选择一级" style="margin-left:20px;" v-if="disabled == true" @change="selectchange1">-->
                        <!--    <el-option-->
                        <!--        v-for="(item,index) in subordinateList"-->
                        <!--        :key="index"-->
                        <!--        :label="item.label"-->
                        <!--        :value="parseInt(item.value)">-->
                        <!--    </el-option>-->
                        <!--  </el-select>-->
                        <!--</el-form-item>-->
                      </div>
                      <!--<el-form-item label="注册号">-->
                      <!--  <el-input clearable style="" v-model="form.registration_number" placeholder="请输入注册号"></el-input>-->
                      <!--</el-form-item>-->
                      <!--<el-form-item label="注册地址">-->
                      <!--  <el-input clearable style="" v-model="form.registration_place" placeholder="请输入注册地址"></el-input>-->
                      <!--</el-form-item>-->
                      <el-form-item label="业务负责人姓名" prop="salesman" label-width="140px">
                        <el-input clearable style="" v-model="form.salesman" placeholder="请输入业务负责人姓名"></el-input>
                      </el-form-item>
                      <el-form-item label="救援类型">
                        <el-checkbox v-model="checked2">事故救援</el-checkbox>
                        <el-checkbox v-model="checked3">非事故救援</el-checkbox>
                        <el-checkbox v-model="checked4">高速救援</el-checkbox>
                      </el-form-item>
                      <!--<el-form-item label="上级客户">-->
                      <!--  &lt;!&ndash;v-el-select-loadmore="loadmore"&ndash;&gt;-->
                      <!--  <el-select v-model="form.pid" filterable placeholder="请选择上级客户">-->
                      <!--    <el-option-->
                      <!--      v-for="(item,index) in List"-->
                      <!--      :key="index"-->
                      <!--      :label="item.channel_name"-->
                      <!--      :value="parseInt(item.id)">-->
                      <!--    </el-option>-->
                      <!--  </el-select>-->
                      <!--</el-form-item>-->
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <el-form-item label="客户名称" prop="">
                        <el-input clearable style="" v-model="form.channel_name" placeholder="请输入客户名称"></el-input>
                      </el-form-item>
                      <!--<el-form-item label="法人">-->
                      <!--  <el-input clearable style="" v-model="form.legal_person" placeholder="请输入法人"></el-input>-->
                      <!--</el-form-item>-->
                      <!--<el-form-item label="法人身份证">-->
                      <!--  <el-input clearable style="" v-model="form.identity_card" placeholder="请输入法人身份证"></el-input>-->
                      <!--</el-form-item>-->
                      <!--<el-form-item label="身份证有效期" label-width="110px">-->
                      <!--  <el-date-picker-->
                      <!--      unlink-panels-->
                      <!--      v-model="form.identity_card_indate"-->
                      <!--      type="daterange"-->
                      <!--      value-format="yyyy-MM-dd"-->
                      <!--      range-separator="至"-->
                      <!--      start-placeholder="开始日期"-->
                      <!--      end-placeholder="结束日期">-->
                      <!--  </el-date-picker>-->
                      <!--  &lt;!&ndash;<DatePicker v-model="form.identity_card_indate" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>&ndash;&gt;-->
                      <!--</el-form-item>-->
                      <el-form-item label="业务负责人电话" prop="salesman_phone" label-width="140px">
                        <el-input clearable style="" v-model="form.salesman_phone" placeholder="请输入业务负责人电话"></el-input>
                      </el-form-item>
                      <el-form-item label="案件报备坐席" prop="salesman" >
                        <el-input clearable style="" v-model="form.salesman" placeholder="请输入"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="12" v-if="checked2 == true">
                    <el-form-item label="事故免拖公里数">
                      <el-input clearable placeholder="请输入免拖公里数"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="checked3 == true">
                    <el-form-item label="非事故免拖公里数" label-width="140px">
                      <el-input clearable placeholder="请输入免拖公里数"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="12" v-if="checked4 == true">
                    <el-form-item label="高速免拖公里数">
                      <el-input clearable placeholder="请输入免拖公里数"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="24">
                    <el-form-item label="系统自动回传(到达现场截止)" label-width="200px">
                      <el-radio v-model="radio3" label="1">启用</el-radio>
                      <el-radio v-model="radio3" label="2">禁用</el-radio>
                    </el-form-item>
                  </el-col>
                </el-row>

              </div>
            </div>
          </div>
        </div>

        <!-- 管理信息 -->
        <div class="card" style="margin-top: 20px;padding: 20px !important;">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              管理信息
            </div>
          </div>

          <div style="margin-top: 20px">
            <el-row :gutter="15">
              <el-col :span="8">
                <!--<el-form-item label="客户代码" prop="client_code">-->
                <!--  <el-input clearable style="" v-model="form.client_code" placeholder="请输入客户代码"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="账号" prop="account">
                  <el-input clearable style="" v-model="form.salesman_phone" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status" v-if="isUpdate == true">
                  <el-radio-group v-model="form.status">
                    <el-radio label="启用" value="0"></el-radio>
                    <el-radio label="禁用" value="1"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--<el-form-item label="姓名" prop="realname">-->
                <!--  <el-input clearable style="" v-model="form.realname" placeholder="请输入姓名"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="密码" prop="password">
                  <el-input clearable v-if="isUpdate === false" v-model="form.password" placeholder="请输入密码"></el-input>
                  <el-input clearable v-if="isUpdate === true" v-model="form.password" placeholder="密码为空时则不修改密码"></el-input>
                  <!--<div v-if="isUpdate === true">-->
                  <!--  <span style="color: red; font-size: 12px;"></span>-->
                  <!--</div>-->
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!--<el-form-item label="手机号" prop="phone">-->
                <!--  <el-input clearable style="" v-model="form.phone" placeholder="请输入手机号"></el-input>-->
                <!--</el-form-item>-->
              </el-col>
            </el-row>
            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="8">-->
            <!--    <el-form-item label="业务负责人姓名" prop="salesman" label-width="120px">-->
            <!--      <el-input clearable style="" v-model="form.salesman" placeholder="请输入业务负责人姓名"></el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="8">-->
            <!--    <el-form-item label="业务负责坐席电话" prop="salesman_phone" label-width="140px">-->
            <!--      <el-input clearable style="" v-model="form.salesman_phone" placeholder="请输入业务负责人电话"></el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
          </div>

        </div>
      </el-form>




      <!--按钮-->
      <div class="card_bottom">
        <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
        <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')" v-auths="[`${$config.uniquePrefix}custom:list:put`]">提交</el-button>
      </div>
    </div>

    <!--计价公式 模块-->
    <div class="Pricingformula" v-if='isShow == 1'>
      <div>
        <Pricingformula :id="id" :data="data" :dict="dict"></Pricingformula>
      </div>
    </div>

    <!--通讯录 模块-->
    <div class="Addressbook" v-if='isShow == 2'>
      <div>
        <Addressbook :id="id" :data="data" :dict="dict"></Addressbook>
      </div>
    </div>

    <!--服务配置 模块-->
    <div class="Addressbook" v-if='isShow == 3'>
      <div>
        <ServiceConfiguration :id="id" :data="data" :dict="dict"></ServiceConfiguration>
      </div>
    </div>

    <!--合同信息 模块-->
    <div class="Addressbook" v-if='isShow == 4'>
      <div>
        <ContractInfo :id="id" :data="data" :dict="dict"></ContractInfo>
      </div>
    </div>

    <!--话术配置 模块-->
    <div class="Addressbook" v-if='isShow == 5'>
      <div>
        <Scriptconfiguration :id="id" :data="data" :dict="dict" @showDetalis="ShowDetalis"></Scriptconfiguration>
      </div>
    </div>

    <!--照片配置 模块-->
    <div class="Addressbook" v-if='isShow == 6'>
      <div>
        <Photoconfiguration :id="id" :data="data" :dict="dict"></Photoconfiguration>
      </div>
    </div>

    <!--定损员 列表-->
    <div class="Addressbook" v-if="isShow == 7">
      <LossAdjuster :id="id" :data="data" :dict="dict"/>
    </div>

    <!--聚合配置-->
    <div class="Addressbook" v-if="isShow == 8">
      <Polymerization :id="id" :data="data" :dict="dict"/>
    </div>


  </div>
</template>

<script>
// 引入计价公式组件
import Pricingformula from './components/Pricingformula.vue'
// 引入通讯录组件
import Addressbook from './components/Addressbook.vue'
// 引入服务配置组件
import ServiceConfiguration from './components/ServiceConfiguration.vue'
// 引入合同信息组件
import ContractInfo from './components/ContractInfo.vue'
// 引入话术配置组件
import Scriptconfiguration from './components/Scriptconfiguration.vue'
// 引入照片模板
import Photoconfiguration from './components/Photoconfiguration.vue'
// 引入定损员列表
import LossAdjuster from './components/Lossadjuster.vue'
// 引入聚合配置
import Polymerization from './components/polymerization.vue'

// 引入的接口
import {Addclient, channelinfo, Editclient, parent_select, son_select} from "@/api/custom";

import Vue from "vue";
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    //字典数据
    dict:Object,
    // 显示计价公式
    xianshi:Number,
  },
  // 组件生效
  components: {
    Pricingformula,
    Addressbook,
    ServiceConfiguration,
    ContractInfo,
    Scriptconfiguration,
    Photoconfiguration,
    LossAdjuster,
    Polymerization
  },

  // Data数据
  data() {
    return {
      isShow:0,
      activeList:[
        {
          // img: require('../../../assets/images/home/custom/icon1active.png'),
          name:'基本信息',
        },
        {
          // img: require('../../../assets/images/home/custom/icon2.png'),
          name:'计价公式',
        },
        {
          // img: require('../../../assets/images/home/custom/icon3.png'),
          name:'通讯录',
        },
        {
          // img: require('../../../assets/images/home/custom/icon4.png'),
          name:'服务配置',
        },
        {
          // img: require('../../../assets/images/home/custom/icon5.png'),
          name:'合同信息',
        },
        {
          // img: require('../../../assets/images/home/custom/icon6.png'),
          name:'话术配置',
        },
        {
          // img: require('../../../assets/images/home/custom/icon6.png'),
          name:'照片配置',
        },
        {
          // img: require('../../../assets/images/home/custom/icon6.png'),
          name:'定损员列表',
        },
        {
          // img: require('../../../assets/images/home/custom/icon6.png'),
          name:'聚合配置',
        },
      ],

      //营业执照
      businessLicenseupload_btn:false,
      businessLicensedialogVisible:false,
      businessLicensedialogImageUrl:'',
      fileList:[],


      // 表单数据
      form: Object.assign({}, this.data),

      // 验证规则
      rules: {
        client_code: [
          { required: true, message: '请输入客户代码', trigger: 'blur' },
          { min: 5, max: 50, message: '长度在5 到 50个字符并且只能输入纯字母', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多20个字符', trigger: 'blur' }
        ],
        status:[
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多10个字符', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在6 到 20个字符', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        salesman:[
          { required: true, message: '请输入业务负责人姓名', trigger: 'blur' },
          { min: 0, max: 18, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        salesman_phone:[
          { required: true, message: '请输入业务负责坐席电话', trigger: 'blur' },
          { min: 5, max: 28, message: '长度在5 到 20个字符', trigger: 'blur' }
        ]
      },
      // 是否是修改
      isUpdate: false,

      List:[],
      //下级
      subordinateList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/client',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      // 父级
      fuji:'',
      disabled:false,

      jiuyuanleixing:'',

      checked2:false,
      checked3:false,
      checked4:false,

      radio3:'',

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.isShow = this.xianshi;
    if(this.isShow == 1){
      // this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      // this.activeList[1].img = require('../../../assets/images/home/custom/icon2active.png');
      // this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      // this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      // this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      // this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
    }

    // 调用获取上级客户列表
    this.getList();

    if (this.data) {
      console.log(this.data)
      this.form = {};

      // 获取详情
      channelinfo(this.data.id).then(res => {
        this.form = res.data;
        this.isUpdate = true;
        // 删除下密码的验证
        Vue.delete(this.rules, 'password');
        // 赋值一下状态
        if(this.form.status === 0) {
          this.form.status = '启用'
        }else {
          this.form.status = '禁用';
        }

        // 营业执照
        if(this.form.business_license != ''){
          let data = {
            url: this.form.business_license
          };
          this.businessLicenseupload_btn = true;
          this.fileList.push(data);
        }

        // 客户
        if(this.form.parent_id == 0){
          this.form.parent_id = this.form.ic_id;
          this.disabled = false;
        }else {
          // 获取二级列表
          son_select(this.form.parent_id).then(async (res) => {
            console.log(res)
            this.subordinateList = res.data;
            if(this.subordinateList.length == 0){
              this.disabled = false
            }else {
              this.disabled = true
            }
          }).catch((res) => {
            this.$Message.error(res.msg);
          })
          this.disabled = true;
        }

      })
    } else {
      this.form = {};
      this.isUpdate = false;
    }

  },

  // 方法集合
  methods: {
    // 选项卡点击事件
    xuanxiangclick(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      // if(item.name == '基本信息'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1active.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '计价公式'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2active.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '通讯录'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3active.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '服务配置'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4active.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if(item.name == '合同信息'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5active.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6.png');
      // }else if (item.name == '话术配置'){
      //   this.activeList[0].img = require('../../../assets/images/home/custom/icon1.png');
      //   this.activeList[1].img = require('../../../assets/images/home/custom/icon2.png');
      //   this.activeList[2].img = require('../../../assets/images/home/custom/icon3.png');
      //   this.activeList[3].img = require('../../../assets/images/home/custom/icon4.png');
      //   this.activeList[4].img = require('../../../assets/images/home/custom/icon5.png');
      //   this.activeList[5].img = require('../../../assets/images/home/custom/icon6active.png');
      // }
    },

    // 返回上级页面
    returnPage(){
      if(this.$route.query.isDetail != undefined && this.$route.query.isDetail == 'true'){
        this.$router.go(-1);
        this.$emit('showDetalis',false) ;
      }else {
        this.$router.push({query:{}})
        this.$emit('showDetalis',false) ;
      }
    },

    // 接收话术配置子组件传递的值
    ShowDetalis(value){
      this.$emit('showDetalis',value) ;
    },

    // 营业执照
    businessLicensePreview(file){
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file,fileList){
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange(){
      this.businessLicenseupload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      this.form.business_license = file.data.urls;
    },

    // 获取客户列表
    getList(){
      // 获取列表
      parent_select().then(async (res) => {
        this.List = res.data;
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },

    // 选择客户
    handleChange(value) {
      console.log(value);
    },

    //选择一级客户 获取二级客户
    selectchange(val){
      console.log(val)
      if(val != ''){
        // 获取列表
        son_select(val).then(async (res) => {
          console.log(res)
          this.subordinateList = res.data;
          if(this.subordinateList.length == 0){
            this.disabled = false
          }else {
            this.disabled = true
          }
          this.form.ic_id = '';
        }).catch((res) => {
          this.$Message.error(res.msg);
        })
      }
    },
    //选择二级
    selectchange1(value){
      console.log(value);
      this.form.ic_id = value;
      this.$forceUpdate();
    },

    //身份证有效期
    datechange(e){
      this.form.identity_card_indate = e;
    },


    // 提交
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 客户
          if(this.disabled == false){
            this.form.ic_id = this.form.parent_id;
          }

          // 判断是创建还是编辑
          if(this.isUpdate === false){
            //创建客户
            Addclient(this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showDetalis',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else {
            if(this.form.status === '启用') {
              this.form.status = 0
            }else {
              this.form.status = 1;
            }
            //编辑客户
            Editclient(this.id,this.form).then(res => {
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showDetalis',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.card{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }

  .condition_b{
    margin-top: 20px;
    .condition_b_img{
      width: 280px;
      height: 255px;
      background-image: url("../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }
    .condition_b_img_anniu{
      width: 94px;
      height: 94px;
    }
    .condition_b_img_text{
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }
    .condition_b_l{
      width: 20%;
      float: left;
    }
    .condition_b_r{
      width: 100%;
      float: right;
      margin-left: 30px;
    }
  }
  // 解决高度坍塌
  .condition_b:after{
    content: '';
    clear: both;
    display: block;
  }
}

.xuanxiang{
  cursor: pointer;
  display: flex;
  .xuanxiang_k{
    width: 150px;
    height: 50px;
    background: #e8e8e8;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #7b7b7b;
      margin-left: 5px;
    }
  }
  .xuanxiang_k1{
    width: 150px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;
    img{
      width: 20px;
      height: 20px;
    }
    span{
      font-size: 20px;
      font-weight: 700;
      color: #FF9B05;
      margin-left: 5px;
    }
  }
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
