<template>
  <!--聚合配置 模块-->
  <div>
    <el-card shadow="never">
      <div class="zong">
        <div class="zong-t">
          <span class="zong-t-font">聚合配置</span>
          <el-button type="primary" @click="open(null)">编辑</el-button>
        </div>
        <div class="zong-b">
          <el-row :gutter="15" class="zong-b-b">
            <el-col :span="6">
              <span>聚合同步：</span>
              <span>否</span>
            </el-col>
            <el-col :span="6">
              <span>聚合改派：</span>
              <span>所有不可改派</span>
            </el-col>
            <el-col :span="6">
              <span>休息技师上传：</span>
              <span>否</span>
            </el-col>
            <el-col :span="6">
              <span>忙碌技师上传：</span>
              <span>否</span>
            </el-col>
          </el-row>
          <el-row :gutter="15" class="zong-b-b">
            <el-col :span="6">
              <span>坐标上传周期:：</span>
              <span>15分钟</span>
            </el-col>
            <el-col :span="6">
              <span>虚拟坐标上传：</span>
              <span>不上传</span>
            </el-col>
            <el-col :span="6">
              <span>实名技师条件：</span>
              <span>实名认证通过</span>
            </el-col>
            <el-col :span="6">
              <span>后台坐标上传时段：</span>
              <span>00:00:00-00:00:00</span>
            </el-col>
          </el-row>
          <el-row :gutter="15" class="zong-b-b">
            <el-col :span="6">
              <span>聚合区域：</span>
              <span>查看</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

    <!--聚合编辑-->
    <polymerization-edit v-if="showpolymerization === true" :id="id" :dict="dict" :data="showpolymerizationcurrent" :visible.sync="showpolymerization"/>

  </div>
</template>

<script>
import PolymerizationEdit from './components/polymerization-edit.vue'

export default {
  components:{
    PolymerizationEdit
  },
  props:{
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
    // 字典数据
    dict:Object,
  },
  data(){
    return{
      showpolymerizationcurrent:null,
      showpolymerization:false
    }
  },

  mounted() {

  },

  methods:{
    open(row){
      this.showpolymerizationcurrent = row;
      this.showpolymerization = true;
    }

  }

}
</script>

<style lang="scss" scoped>
  .zong{
    background: #FAFAFA;
  }
  .zong-t{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E6EBF5;
  }
  .zong-t-font{
    font-size: 18px;
  }
  .zong-b{
    padding: 10px;
    border: 1px solid #E6EBF5;
    border-top: none;
  }
  .zong-b-b{
    margin-top: 15px;
  }
</style>
