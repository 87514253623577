<!-- 聚柏合配置编辑 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'聚柏合配置编辑':'聚柏合配置编辑'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="120px">

      <el-row :gutter="15">
        <el-col :span="18">
          <div class="pricingRules">
            <el-form-item label='聚合同步:'>
              <el-select v-model="form.value" placeholder="请选择">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='聚合改派:'>
              <el-select v-model="form.value1" placeholder="请选择">
                <el-option label="所有可改派" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='休息技师上传:'>
              <el-select v-model="form.value2" placeholder="请选择">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='忙碌技师上传:'>
              <el-select v-model="form.value3" placeholder="请选择">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='坐标上传周期:'>
              <el-input placeholder="请输入内容" v-model="form.input2">
                <template slot="append">分钟</template>
              </el-input>
            </el-form-item>
            <el-form-item label='技师实名条件:'>
              <el-select v-model="form.value5" placeholder="请选择">
                <el-option label="姓名身份证号完整" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='虚拟坐标上传:'>
              <el-select v-model="form.value4" placeholder="请选择">
                <el-option label="上传符合条件技师的虚拟坐标" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='后台坐标上传时段:'>
              <el-time-picker
                  is-range
                  v-model="form.time"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围">
              </el-time-picker>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="provinceAndCity">
            <div class="provinceAndCity_t">
              <span style="vertical-align: middle">聚合区域：</span>
              <el-checkbox v-model="selectAll" @change="handleCheckAllChange">全选</el-checkbox>
            </div>
            <!--省市区-->
            <div class="provinceAndCity_b">
              <!--@check="treeCheck"  实现单选-->
              <el-tree
                  :data="city"
                  ref="tree"
                  show-checkbox
                  node-key="value"
                  :default-checked-keys="[keys]"
                  @check-change="handleCheckChange"
                  :props="defaultProps">
              </el-tree>
            </div>
          </div>
        </el-col>
      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
//引入的接口
import {
  taximeterget_city
} from '@/api/yunli'

export default {
  name: '',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 客户id
    id:Number,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 省市区
      //城市列表
      city:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //默认选中的节点
      keys:[],
      selectList:[],
      // 区域 全选按钮
      selectAll:false,

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data);
      this.form = this.data;
      console.log(this.form);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getCity();
  },


  methods: {
    // 获取城市列表
    getCity(){
      taximeterget_city().then(res => {
        console.log(res);
        this.city = res.data;
      })
    },
    //城市全选
    handleCheckAllChange(val) {
      if (this.selectAll) {
        this.$refs.tree.setCheckedNodes(this.city);
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    //获取选择的区域
    handleCheckChange(){
      //console.log(this.$refs.tree.getCheckedNodes());  // 这是选中节点数组
      this.selectList = this.$refs.tree.getCheckedNodes();
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {

        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">
.provinceAndCity{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity_b{
  margin-top: 10px;
}
.pricingRules{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.pricingRules_t_bg{
  padding: 10px 15px 10px 15px;
  background: #f0f1f3;
  border: 1px solid #dfe2e7;
  font-size: 16px;
  font-weight: 500;
  color: #242832;
}
</style>
